// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#lt-yantr.loan-amount-calculator {
  text-align: center;
  max-width: 860px;
  margin: 0 auto;
  padding: 30px;
  border: 1px solid rgba(204, 204, 204, 0.6);
  border-top: 7px solid #078181;
  border-radius: 8px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
}
@media (max-width: 767px) {
#lt-yantr.loan-amount-calculator {
    padding: 40px 20px;
}
}
#lt-yantr.loan-amount-calculator .mortgage-heading {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}
#lt-yantr.loan-amount-calculator .mortgage-heading .mortgage-amount-title {
  font-size: 21px;
  font-weight: 700;
  line-height: 29px;
  color: #000;
}
@media (max-width: 767px) {
#lt-yantr.loan-amount-calculator .mortgage-heading .mortgage-amount-title {
    font-size: 16px;
    line-height: 24px;
}
}
#lt-yantr.loan-amount-calculator .mortgage-heading .mortgage-amount {
  color: #0069BA;
  font-size: 27px;
  font-weight: 400;
  line-height: 36px;
}
#lt-yantr.loan-amount-calculator .btn {
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  max-width: 300px;
  white-space: normal;
  margin-bottom: 24px;
  padding: 20px 24px;
  line-height: 100%;
  border: 0;
  border-radius: 4px;
}
@media (max-width: 767px) {
#lt-yantr.loan-amount-calculator .btn {
    min-width: 100%;
}
}
#lt-yantr.loan-amount-calculator .btn-blue {
  background: #0069BA;
}
#lt-yantr.loan-amount-calculator .btn-blue:hover, #lt-yantr.loan-amount-calculator .btn-blue:active, #lt-yantr.loan-amount-calculator .btn-blue:focus {
  background: #005495;
}
#lt-yantr.loan-amount-calculator .disclosure-link {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #0069BA;
  margin-top: 0;
}
#lt-yantr.loan-amount-calculator .disclosure-link:hover {
  color: #005495;
  text-decoration: none;
}
#lt-yantr.loan-amount-calculator .vue-slider-block {
  width: 90%;
  margin: 32px auto 20px auto;
}
@media (max-width: 480px) {
#lt-yantr.loan-amount-calculator .vue-slider-block {
    width: 95%;
}
}
#lt-yantr.loan-amount-calculator .vue-slider-component .vue-slider {
  background-color: #c6cdd0;
  height: 4px !important;
}
#lt-yantr.loan-amount-calculator .vue-slider-component .vue-slider-process {
  background-color: #0069BA;
}
#lt-yantr.loan-amount-calculator .vue-slider-component .vue-slider-dot {
  background-color: #fff;
  border: 3px solid #0069BA;
  top: -7px !important;
  width: 28px !important;
  height: 28px !important;
  margin: -5px 0px 0px -8px;
  border-radius: 100%;
}
#lt-yantr.loan-amount-calculator .vue-slider-component .vue-slider-dot .vue-slider-dot-handle {
  display: none;
}
#lt-yantr.loan-amount-calculator .vue-slider-component .vue-slider-dot.vue-slider-always .vue-slider-tooltip-wrap {
  display: none !important;
}
#lt-yantr.loan-amount-calculator .disclosure-term {
  display: flex;
  justify-content: center;
  line-height: 20px;
}
#lt-yantr.loan-amount-calculator .disclosure-term .optional-line {
  padding: 0px 10px;
  color: #E3E6E8;
}
@media (max-width: 365px) {
#lt-yantr.loan-amount-calculator .disclosure-term .optional-line {
    padding: 0px 5px;
}
}
#lt-yantr.loan-amount-calculator .disclosure-term .terms {
  color: #4B5155;
  font-size: 12px;
  font-weight: 400;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
