var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "loan-amount-calculator", attrs: { id: "lt-yantr" } },
    [
      _c("div", { staticClass: "mortgage-heading" }, [
        _c("div", { staticClass: "mortgage-amount-title" }, [
          _vm._v(_vm._s(_vm.headline)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mortgage-amount" }, [
          _vm._v(_vm._s(_vm.displayAmount())),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vue-slider-block" },
        [
          _c("vue-slider", {
            attrs: {
              max: _vm.loanAmtSlider.max,
              min: _vm.loanAmtSlider.min,
              clickable: _vm.loanAmtSlider.clickable,
              interval: _vm.loanAmtSlider.interval,
            },
            on: {
              dragging: function ($event) {
                return _vm.formatLoanAmtVal($event.currentValue)
              },
            },
            model: {
              value: _vm.LoanAmount,
              callback: function ($$v) {
                _vm.LoanAmount = $$v
              },
              expression: "LoanAmount",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-blue",
          attrs: { type: "submit" },
          on: {
            click: function ($event) {
              return _vm.redirectToDestination()
            },
          },
        },
        [_vm._v(_vm._s(_vm.DestinationButtonText))]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "clear-fix" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "disclosure-term" },
        [
          _vm.isToShowDisclosureLink()
            ? _c("advertising-disclosures", {
                attrs: {
                  "disclosure-text": _vm.DisclosureText,
                  "disclosure-tokens": _vm.DisclosureTokens,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isToShowDisclosureLink()
            ? _c("span", { staticClass: "optional-line" }, [_vm._v("|")])
            : _vm._e(),
          _vm._v(" "),
          _vm.DisplayTermsAndConditions
            ? _c("div", { staticClass: "terms" }, [
                _vm._v(_vm._s(_vm.termsAndConditions)),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }