import loadApp from '../../shared/services/bootstrap';
import App from './app.vue';

const appElements = document.querySelectorAll('#loan-amount-calculator-app');

// Iterate over each element and initialize the Vue app
appElements.forEach((el, index) => {
    // Create a unique ID for each element
    const uniqueId = `loan-amount-calculator-app-${index}`;
    el.id = uniqueId;

    // Initialize the Vue app for this specific element
    loadApp(`#${uniqueId}`, App);
});
